import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from 'react-router-dom';

import { Drawer, Input, Flex, Modal, Space, Switch, Table, Tag } from "antd";
import { CheckCircleFilled, RightCircleFilled, PauseCircleFilled, EditFilled, FileWordFilled, PlusOutlined, DownOutlined, RedoOutlined, DeleteFilled, CopyOutlined, ClearOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';
import { jsonrepair } from 'jsonrepair';

import "./styles.scss";

import { ScenariosContext } from '../index';
import { ActionButton } from "../../../components/elements/Button";
import { DropdownMenu } from "../../../components/elements/DropdownMenu";
import { SortableList } from "../SortableList";
import { defaultParameterNotation, transformDataObject, NestedObjectTable } from "../../../components/elements/JsonObjTable";

import { defaultMenuProps } from '../defaultEventTemplates';

const { TextArea } = Input;

const generateUniqueId = () => uuidv4();

const templateElemsList = (items) => {
    switch (items.length) {
        case 0:
            return [defaultParameterNotation({
                key: "",
                type: "string",
                required: true,
                exatclyMatch: false,
                value: "",
            })];
        default:
            return items;
    }
}

const scenarioStartPageMatchTypes = [
    {
        label: "exact matches",
        key: "exact_matches",
    },
    {
        label: "matches regex",
        key: "matches_regex",
    },
    {
        label: "contains",
        key: "contains",
    },
];

const defaultScenarioObject = () => ({
    scenarioID: generateUniqueId(),
    scenarioTitle: "",
    active: false,
    urlStartingPoint: "",
    urlStartingPointMatchType: "",
    cookiesAcceptance: {
        active: false,
        cookiesPassed: false,
        steps: [],
    },
    loginInfo: {
        required: false,
        isLoggedIn: false,
        steps: [],
    },
    steps: [],
});

const ConfirmationModalWindow = (props) => {
    const {
        open = false,
        onOk = () => { },
        onCancel = () => { },
        text = "",
    } = props;

    return (
        <Modal
            title={"Confirm action"}
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
                <Flex gap={5}>
                    <CancelBtn />
                    <OkBtn />
                </Flex>
            )}>
            {text}
        </Modal>
    );
}


const ScenariosMenu = () => {
    const {
        companyId = "",
        configParams = {},
        setConfigParams = () => { },
        scrapedEventsDescLink = "",
        scenariosList = [],
        drawerGlobalState = false,
        setDrawerGlobalState = () => { },
        saveFunction = () => { },
        defaultEventTemplates = [],
        simplifyDLGEventJson = () => { },
        transformDataObject = () => { },
        error = () => { },
    } = useContext(ScenariosContext);


    // Constant variables
    const defaultPlacement = "right";
    const firstDrawerTitle = "Scenarios";
    const secondDrawerTitle = "Scenario Steps List";
    const thirdDrawerTitle = "DataLayer Template";
    const cookiesDrawerTitle = "Cookies Scenario Steps List";
    const loginDrawerTitle = "Login Scenario Steps List";
    const firstDrawerWidth = "70%";
    const secondDrawerWidth = "70%";
    const thirdDrawerWidth = "70%";

    const defaultMatchType = scenarioStartPageMatchTypes[0]?.key;

    const [jsonBtnText, setJsonBtnText] = useState("Add JSON");
    const [jsonBtnIcon, setJsonBtnIcon] = useState(<PlusOutlined />);


    // Drawers control area
    const [secondDrawerVisible, setSecondDrawerVisible] = useState(false);
    const [thirdDrawerVisible, setThirdDrawerVisible] = useState(false);
    const [cookiesDrawerVisible, setCookiesDrawerVisible] = useState(false);
    const [loginDrawerVisible, setLoginDrawerVisible] = useState(false);

    const showSecondDrawer = () => setSecondDrawerVisible(true);
    const closeSecondDrawer = () => setSecondDrawerVisible(false);

    const showCookiesDrawer = () => setCookiesDrawerVisible(true);
    const closeCookiesDrawer = () => setCookiesDrawerVisible(false);

    const showLoginDrawer = () => setLoginDrawerVisible(true);
    const closeLoginDrawer = () => setLoginDrawerVisible(false);

    const showThirdDrawer = (item, stepId) => {
        const parametersList = templateElemsList(item);
        setSelectedTemplate(parametersList);
        setSelectedTemplateStr(JSON.stringify(parametersList) || "");
        setSelectedStepId(stepId);
        setThirdDrawerVisible(true);
    };
    const closeThirdDrawer = () => setThirdDrawerVisible(false);


    // Scenarios list modal
    const [curScenariosList, setCurScenariosList] = useState(null);
    const [curScenariosEvents, setCurScenariosEvents] = useState({});

    useEffect(() => {
        setCurScenariosList(scenariosList);
        const scenariosDropdownList = scenariosList.map((elem, idx) => ({
            key: elem?.scenarioID || idx,
            label: elem?.scenarioTitle || `Untitled Scenario ${idx + 1}`,
        }));

        setStepsScenarioOptions(getScenariosListProps({
            listUpdateFunction: setScenarioSteps,
            scenariosDropdownList: scenariosDropdownList,
            stepsType: "steps",
            modalCancelFunction: handleStepsListModalCancel,
            modalStateChangeFunction: setStepsListChangeModalState,
        }));

        setCookiesStepsScenarioOptions(getScenariosListProps({
            listUpdateFunction: setScenarioCookiesSteps,
            scenariosDropdownList: scenariosDropdownList,
            stepsType: "cookies",
            modalCancelFunction: handleCookiesStepsListModalCancel,
            modalStateChangeFunction: setCookiesStepsListChangeModalState,
        }));

        setLoginStepsScenarioOptions(getScenariosListProps({
            listUpdateFunction: setScenarioLoginSteps,
            scenariosDropdownList: scenariosDropdownList,
            stepsType: "login",
            modalCancelFunction: handleLoginStepsListModalCancel,
            modalStateChangeFunction: setLoginStepsListChangeModalState,
        }));

        const scenariosEvents = {};

        scenariosList.forEach(elem => {
            const events = elem?.steps?.filter(step =>
                ["collect_dl_object", "collect_dl_before_navigation"].includes(step?.type));

            scenariosEvents[elem.scenarioID] = events?.map(event => ({
                stepNum: event?.step,
                eventName: event?.value,
            })) || [];
        });

        setCurScenariosEvents(scenariosEvents);
    }, [scenariosList]);

    const scenarioColumns = [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Scenario Title',
            dataIndex: 'scenarioTitle',
            key: 'scenarioTitle',
        },
        {
            title: 'Starting Page',
            dataIndex: 'urlStartingPoint',
            key: 'urlStartingPoint',
        },
        {
            title: 'Events',
            dataIndex: 'events',
            key: 'events',
            render: (text, record, index) => curScenariosEvents[record?.scenarioID]?.map((event, idx) =>
                (event?.eventName ? <Tag key={idx}>{event?.eventName}</Tag> : undefined)
            ),
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record, index) => text ?
                <CheckCircleFilled style={{ fontSize: 20 }} />
                :
                <PauseCircleFilled style={{ fontSize: 20 }} />,
        },
        {
            title: '',
            dataIndex: 'key',
            key: 'key',
            render: (_, record) => (
                <EditFilled size="large" />
            ),
        },
    ];


    // Scenario edit modal
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [scenarioTitle, setScenarioTitle] = useState("");
    const [scenarioStatus, setScenarioStatus] = useState(false);
    const [scenarioStartPage, setScenarioStartPage] = useState(false);
    const [scenarioStartPageMatchType, setScenarioStartPageMatchType] = useState(false);
    const [scenarioSteps, setScenarioSteps] = useState([]);

    const [scenarioCookies, setScenarioCookies] = useState(null);
    const [scenarioCookiesStatus, setScenarioCookiesStatus] = useState(false);
    const [scenarioCookiesSteps, setScenarioCookiesSteps] = useState(false);

    const [scenarioLogin, setScenarioLogin] = useState(null);
    const [scenarioLoginStatus, setScenarioLoginStatus] = useState(false);
    const [scenarioLoginSteps, setScenarioLoginSteps] = useState(false);

    const [scenarioEvents, setScenarioEvents] = useState([]);

    // Updating data with selecting a new scenario from the list
    useEffect(() => {
        setScenarioTitle(selectedScenario?.scenarioTitle || "");
        setScenarioStatus(!!selectedScenario?.active);
        setScenarioStartPage(selectedScenario?.urlStartingPoint || "");
        setScenarioStartPageMatchType(selectedScenario?.urlStartingPointMatchType || defaultMatchType);
        setScenarioSteps(selectedScenario?.steps || []);
        setScenarioCookies(selectedScenario?.cookiesAcceptance || {});
        setScenarioLogin(selectedScenario?.loginInfo || {});
        setScenarioEvents(curScenariosEvents[selectedScenario?.scenarioID]);
    }, [selectedScenario]);

    useEffect(() => {
        setScenarioCookiesStatus(!!scenarioCookies?.active);
        setScenarioCookiesSteps(scenarioCookies?.steps || []);
    }, [scenarioCookies]);

    useEffect(() => {
        setScenarioLoginStatus(!!scenarioLogin?.required);
        setScenarioLoginSteps(scenarioLogin?.steps || []);
    }, [scenarioLogin]);


    const menuMatchTypes = {
        items: scenarioStartPageMatchTypes,
        onClick: (e) => setScenarioStartPageMatchType(e?.key),
    };


    // Scenarios list editing area
    const handleEditScenarioClick = (id) => {
        const scenarioObj = curScenariosList.find(elem => elem.scenarioID === id);
        setSelectedScenario(scenarioObj);
        setSelectedTemplateStr("");
        showSecondDrawer();
    };

    const handleAddScenarioClick = () => {
        const newScenario = defaultScenarioObject();
        setSelectedScenario(newScenario);
        setCurScenariosList([...curScenariosList, newScenario]);
        setSelectedTemplateStr("");
        showSecondDrawer();
    };

    const handleDeleteScenarioClick = (id) => {
        setCurScenariosList(curScenariosList.filter(elem => elem.scenarioID !== id));
        setScenarioRemoveConfirmation(false);
        closeSecondDrawer();
    };

    const [scenarioRemoveConfirmation, setScenarioRemoveConfirmation] = useState(false);


    // Steps list editing area
    const [stepsScenarioOptions, setStepsScenarioOptions] = useState({});
    const [cookiesStepsScenarioOptions, setCookiesStepsScenarioOptions] = useState({});
    const [loginStepsScenarioOptions, setLoginStepsScenarioOptions] = useState({});

    const [stepsListChangeModalState, setStepsListChangeModalState] = useState(false);
    const [cookiesStepsListChangeModalState, setCookiesStepsListChangeModalState] = useState(false);
    const [loginStepsListChangeModalState, setLoginStepsListChangeModalState] = useState(false);

    const handleStepsListModalCancel = () => handleStepsListModalChangeState({
        newState: false,
        modalStateChangeFunction: setStepsListChangeModalState,
    });
    const handleCookiesStepsListModalCancel = () => handleStepsListModalChangeState({
        newState: false,
        modalStateChangeFunction: setCookiesStepsListChangeModalState,
    });
    const handleLoginStepsListModalCancel = () => handleStepsListModalChangeState({
        newState: false,
        modalStateChangeFunction: setLoginStepsListChangeModalState,
    });

    const [stepsListChangeModalOkFunction, setStepsListChangeModalOkFunction] = useState(() => { });

    const handleStepsListModalChangeState = (props) => {
        const {
            newState = false,
            okFunction = () => { },
            modalStateChangeFunction = () => { },
        } = props;

        modalStateChangeFunction(newState);
        setStepsListChangeModalOkFunction(() => okFunction);
    };

    const getScenariosListProps = (props) => {
        const {
            listUpdateFunction = () => { },
            scenariosDropdownList = [],
            stepsType = "",
            modalCancelFunction = () => { },
            modalStateChangeFunction = () => { },
        } = props;

        return {
            items: scenariosDropdownList,
            onClick: (e) => handleStepsListModalChangeState({
                newState: true,
                okFunction: () => copyStepsListFromAnotherScenario({
                    scenarioId: e?.key,
                    listUpdateFunction,
                    stepsType,
                    modalCancelFunction,
                }),
                modalStateChangeFunction,
            }),
        };
    };

    const clearStepsList = (props) => {
        const {
            listUpdateFunction = () => { },
            modalCancelFunction = () => { },
        } = props;

        listUpdateFunction([]);

        modalCancelFunction();
    };

    const copyStepsListFromAnotherScenario = (props) => {
        const {
            scenarioId = "",
            listUpdateFunction = () => { },
            stepsType = "",
            modalCancelFunction = () => { },
        } = props;

        const scenario = curScenariosList?.find(elem => elem?.scenarioID === scenarioId);
        let steps = [];

        switch (stepsType) {
            case "steps":
                steps = scenario?.steps || [];
                break;
            case "cookies":
                steps = scenario?.cookiesAcceptance?.steps || [];
                break;
            case "login":
                console.log(scenario?.loginInfo);
                steps = scenario?.loginInfo?.steps || [];
                break;
        }

        listUpdateFunction(steps);

        modalCancelFunction();
    };


    // DataLayer template modal
    const [selectedStepId, setSelectedStepId] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateStr, setSelectedTemplateStr] = useState(null);

    const menuProps = {
        items: defaultMenuProps,
        onClick: (e) => addDefaultEcommerceObject(e?.key),
    };

    const clearJSON = () => {
        setSelectedTemplate(null);
        setSelectedTemplateStr(null);
    };

    const addDefaultEcommerceObject = (eventName) => {
        const obj = defaultEventTemplates[eventName];
        setSelectedTemplate(obj);
        setSelectedTemplateStr(JSON.stringify(obj));
    };


    // Edit JSON modal window
    const [dlEditModalTitle, setDlEditModalTitle] = useState("");
    const [dlEditModalState, setDlEditModalState] = useState(false);
    const [dlEditModalContent, setDlEditModalContent] = useState(false);
    const [editedTextfieldValue, setEditedTextfieldValue] = useState("");
    const defaultEventTemplate = { "": "" };

    useEffect(() => {
        const initialTemplate =
            selectedTemplate?.length === 1 &&
            selectedTemplate[0].key === "" &&
            selectedTemplate[0].value === "";

        const text = initialTemplate ? "Add JSON" : "Edit JSON";
        const icon = initialTemplate ? <PlusOutlined /> : <EditFilled />;

        setJsonBtnText(text);
        setJsonBtnIcon(icon);
    }, [selectedTemplate]);


    // Modal window event handlers
    const handleEditDlModalOk = () => {
        try {
            const value = jsonrepair(editedTextfieldValue || JSON.stringify(defaultEventTemplate));
            saveEditedDlObjectData(value);
        }
        catch (err) {
            console.log(err);
            error("Incorrect value! Please check the JSON object and try again.");
        }
    };
    const saveEditedDlObjectData = (value) => {
        const transformedTextFieldValue = transformDataObject(JSON.parse(value));
        setSelectedTemplate(transformedTextFieldValue);
        setSelectedTemplateStr(JSON.stringify(transformedTextFieldValue));

        handleSaveTemplate(transformedTextFieldValue);

        setDlEditModalState(false);
    };
    const handleEditDlModalCancel = () => setDlEditModalState(false);

    const handleEditTemplateEvent = () => {
        setDlEditModalContent(defaultModalContent());
        setDlEditModalState(true);
    };

    const defaultModalContent = () => {
        const placeholderObj = {
            "event": "event_name",
            "ecommerce": {
                "currency": "string",
                "value": "number",
                "items": [
                    {
                        "item_id": "string",
                        "item_name": "string",
                    }
                ]
            }
        };
        const placeholderText = JSON.stringify(placeholderObj, undefined, 2);

        const defaultValueTemplate = simplifyDLGEventJson(selectedTemplate);
        const defaultValueStr = JSON.stringify(defaultValueTemplate, null, 2);
        setEditedTextfieldValue(defaultValueStr);

        setDlEditModalTitle("Template DataLayer object");

        return (
            <>
                <p>Add your dataLayer template in the box below.</p>
                <TextArea
                    name="datalayer_object_input"
                    key={editedTextfieldValue}
                    rows={12}
                    placeholder={placeholderText}
                    defaultValue={defaultValueStr}
                    onInput={(e) => setEditedTextfieldValue(e.target.value)}
                    style={{
                        fontFamily: "monospace",
                        fontSize: "14px",
                        backgroundColor: "#f7f7f7",
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "4px",
                        whiteSpace: "pre-wrap",
                    }}
                />
            </>
        );
    };


    // Saving data
    const handleSaveTemplate = (template = []) => {
        const updatedSteps = scenarioSteps.map((elem) => {
            switch (elem.stepId === selectedStepId) {
                case true:
                    elem.dataLayerObjToFind = template;
                    return elem;

                default:
                    return elem;
            }
        });

        const templateSimplified = simplifyDLGEventJson(template);
        const templateStr = JSON.stringify(templateSimplified);
        setEditedTextfieldValue(templateStr);

        setSelectedTemplate(template);
        setSelectedTemplateStr(JSON.stringify(template));
        handleSaveSteps(updatedSteps);
    };

    const handleSaveSteps = (steps = []) => {
        setScenarioSteps(steps);

        const newScenarioObj = selectedScenario;
        newScenarioObj.steps = steps;

        setSelectedScenario(newScenarioObj);

        handleSaveScenarios();
    };

    const handleSaveScenarios = () => {
        let newScenariosList = [];

        if (selectedScenario) {
            const newScenarioObj = selectedScenario;
            newScenarioObj.scenarioTitle = scenarioTitle;
            newScenarioObj.active = scenarioStatus;
            newScenarioObj.urlStartingPoint = scenarioStartPage;
            newScenarioObj.urlStartingPointMatchType = scenarioStartPageMatchType;
            newScenarioObj.steps = scenarioSteps;

            const newCookiesObj = { ...scenarioCookies };
            newCookiesObj.active = scenarioCookiesStatus;
            newCookiesObj.steps = scenarioCookiesSteps;
            setScenarioCookies(newCookiesObj);

            const newLoginObj = { ...scenarioLogin };
            newLoginObj.required = scenarioLoginStatus;
            newLoginObj.steps = scenarioLoginSteps;
            setScenarioLogin(newLoginObj);

            newScenarioObj.cookiesAcceptance = newCookiesObj;
            newScenarioObj.loginInfo = newLoginObj;

            newScenariosList = curScenariosList.map(elem => {
                switch (selectedScenario.scenarioID === elem.scenarioID) {
                    case true:
                        return newScenarioObj;
                    default:
                        return elem;
                }
            });
        }

        setCurScenariosList(newScenariosList);
    };

    const handleSaveAction = (level) => {
        switch (level) {
            case "templates":
                handleSaveTemplate(selectedTemplate);
                closeThirdDrawer();
                break;
            case "steps":
                handleSaveSteps(scenarioSteps);
                closeSecondDrawer();
                break;
            case "cookies":
                handleSaveScenarios();
                closeCookiesDrawer();
                break;
            case "login":
                handleSaveScenarios();
                closeLoginDrawer();
                break;
            case "scenarios":
                handleSaveScenarios();
                setDrawerGlobalState(false);
                break;
        }

        const newConfigParams = { ...configParams };
        newConfigParams.scenarios = curScenariosList;

        setConfigParams(newConfigParams);
        setIsConfigParamsUpdatedBeforeSave(true);
    };


    // Data saving functionality control
    const [isConfigParamsUpdatedBeforeSave, setIsConfigParamsUpdatedBeforeSave] = useState(false);

    useEffect(() => {
        if (isConfigParamsUpdatedBeforeSave) {
            saveFunction();
            setIsConfigParamsUpdatedBeforeSave(false);
        }
    }, [isConfigParamsUpdatedBeforeSave]);


    return (
        <div>
            {/* Scenario drawer menu. */}
            <Drawer
                title={firstDrawerTitle}
                placement={defaultPlacement}
                onClose={() => setDrawerGlobalState(false)}
                open={drawerGlobalState}
                closable={true}
                width={firstDrawerWidth}
            >
                <div className={`modal-elems-rows scenarios-modal-padding`}>
                    <div>
                        <h5 className='small-heading'>CONFIGURATION</h5>
                        <h2 className='header-letter-spacing'>DataLayer events via scraper</h2>
                    </div>
                    <div style={{ marginBottom: 0 }} className="container-guide modal-panel-button-row">
                        <div>
                            <Link className="panel-button-container grey-bg" target="_blank" to={scrapedEventsDescLink}>
                                <div className="file-icon" style={{ color: '$cc-blue' }}>
                                    <FileWordFilled size={30} />
                                </div>
                                <div className="panel-button-text">
                                    <div className="panel-button-header">Implementation guide</div>
                                    <div className="panel-button-desc">Guide how to set-up events</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <h3 className="modal-category">Overview of your scenarios</h3>
                        <p>Update, add or delete the dataLayer events that need to be monitored.</p>
                    </div>

                    <div>
                        <div
                            className="btn btn-sm cc-btn-outline-blue"
                            onClick={handleAddScenarioClick}>
                            + add scenario
                        </div>
                    </div>

                    {/* Table with scenarios list. */}
                    <Table
                        size="small"
                        columns={scenarioColumns}
                        dataSource={curScenariosList}
                        rowKey="scenarioID"
                        rowClassName="scenarios-list-row"
                        onRow={(record) => ({
                            onClick: () => handleEditScenarioClick(record?.scenarioID),
                        })}
                    />
                </div>

                {/* Scenario steps modal window. */}
                <Drawer
                    title={secondDrawerTitle}
                    placement={defaultPlacement}
                    onClose={closeSecondDrawer}
                    open={secondDrawerVisible}
                    closable={true}
                    width={secondDrawerWidth}
                >
                    <ConfirmationModalWindow
                        open={stepsListChangeModalState}
                        onOk={stepsListChangeModalOkFunction}
                        onCancel={handleStepsListModalCancel}
                        text={"Are you sure you want to update the steps list?"}
                    />

                    <div key={isConfigParamsUpdatedBeforeSave} className={`modal-elems-rows scenarios-modal-padding`}>
                        <div>
                            <h5 className='small-heading'>CONFIGURATION</h5>
                            <h2 className='header-letter-spacing'>Scenario Steps</h2>
                            {scenarioEvents?.map((event, idx) => (
                                event?.eventName ?
                                    <Tag key={idx}>{event?.stepNum} - {event?.eventName}</Tag>
                                    : undefined
                            ))}
                        </div>

                        <Space size={25}>
                            {/* Switch for scenario status. */}
                            <Space>
                                <Switch
                                    value={scenarioStatus}
                                    onChange={(value) => setScenarioStatus(value)}
                                />
                                <label>{scenarioStatus ? "Enabled" : "Paused"}</label>
                            </Space>
                            <Space>
                                {/* Scenario for cookies bypass. */}
                                <ActionButton
                                    text={<Space><RightCircleFilled />Cookies scenario</Space>}
                                    onClickFunction={() => showCookiesDrawer(true)}
                                    type="primary"
                                />
                                {/* Scenario for log in process. */}
                                <ActionButton
                                    text={<Space><RightCircleFilled />Login scenario</Space>}
                                    onClickFunction={() => showLoginDrawer(true)}
                                />
                                {/* Removing scenario. */}
                                <ActionButton
                                    text={<Space><DeleteFilled />Remove scenario</Space>}
                                    onClickFunction={() => setScenarioRemoveConfirmation(true)}
                                    danger={true}
                                    type="primary"
                                />
                                {/* Copy steps from another scenario */}
                                <DropdownMenu
                                    text="Import steps"
                                    menuProps={stepsScenarioOptions}
                                    type="primary"
                                    loading={false}
                                    icon={<CopyOutlined />}
                                    iconPosition="start"
                                />
                                {/* Clear steps list */}
                                <ActionButton
                                    text={<Space><ClearOutlined />Clear steps</Space>}
                                    onClickFunction={() =>
                                        handleStepsListModalChangeState({
                                            newState: true,
                                            okFunction: () => clearStepsList({
                                                listUpdateFunction: setScenarioSteps,
                                                modalCancelFunction: handleStepsListModalCancel,
                                            }),
                                            modalStateChangeFunction: setStepsListChangeModalState,
                                        })
                                    }
                                />
                            </Space>
                        </Space>

                        {/* Scenario title and starting point. */}
                        <Space size={20}>
                            <Space direction="vertical">
                                <h3 className="modal-category">Title</h3>
                                <Input
                                    name="scenario_title"
                                    value={scenarioTitle} placeholder="Scenario title"
                                    style={{
                                        width: "20rem",
                                    }}
                                    onChange={(e) => setScenarioTitle(e.target.value)}
                                />
                            </Space>
                            <Space direction="vertical">
                                <h3 className="modal-category">Starts at</h3>
                                <Space size={5}>
                                    <DropdownMenu
                                        type=""
                                        text={scenarioStartPageMatchTypes.find(
                                            elem => elem.key === scenarioStartPageMatchType
                                        )?.label || scenarioStartPageMatchTypes[0]?.label}
                                        menuProps={menuMatchTypes}
                                        icon={<DownOutlined />}
                                    />
                                    <Input
                                        name="scenario_start_page"
                                        value={scenarioStartPage} placeholder="Scenario start page"
                                        style={{
                                            width: "20rem",
                                        }}
                                        onChange={(e) => setScenarioStartPage(e.target.value)}
                                    />
                                </Space>
                            </Space>
                        </Space>

                        {/* Dynamically sortable steps list. */}
                        <Space size={10} direction="vertical" style={{ paddingBottom: "5rem" }}>
                            <h3 className="modal-category">Steps</h3>
                            <div style={{ margin: "auto" }}>
                                <SortableList
                                    items={scenarioSteps}
                                    handleDataLayerObjEdit={showThirdDrawer}
                                    handleStepsUpdate={setScenarioSteps}
                                />
                            </div>
                        </Space>
                    </div>

                    {/* Modal window confirmation for deleting step. */}
                    <Modal
                        open={scenarioRemoveConfirmation}
                        title="Confirm"
                        onOk={() => handleDeleteScenarioClick(selectedScenario?.scenarioID)}
                        onCancel={() => setScenarioRemoveConfirmation(false)}
                        footer={(_, { OkBtn, CancelBtn }) => (
                            <Flex gap={5}>
                                <CancelBtn />
                                <OkBtn />
                            </Flex>
                        )}
                    >
                        <p>Do you want to delete this scenario?</p>
                    </Modal>

                    {/* DataLayer object template edit modal window. */}
                    <Drawer
                        title={thirdDrawerTitle}
                        placement={defaultPlacement}
                        onClose={closeThirdDrawer}
                        open={thirdDrawerVisible}
                        closable={true}
                        width={thirdDrawerWidth}
                    >
                        {/* Modal window for editing the JSON-object representation of the template. */}
                        <Modal
                            title={dlEditModalTitle}
                            open={dlEditModalState}
                            onOk={handleEditDlModalOk}
                            onCancel={handleEditDlModalCancel}
                            footer={(_, { OkBtn, CancelBtn }) => (
                                <Flex gap={5}>
                                    <CancelBtn />
                                    <OkBtn />
                                </Flex>
                            )}>
                            {dlEditModalContent}
                        </Modal>

                        <div className="modal-elems-rows scenarios-modal-padding">
                            <div>
                                <h5 className='header-submodal small-heading'>CONFIGURATION</h5>
                                <h2 className="header-submodal header-letter-spacing">
                                    DataLayer event template
                                </h2>
                            </div>

                            <div>
                                <h3 className="modal-category fw-bold">
                                    Add event details
                                </h3>
                                <p className='mb-0'>
                                    To enable monitoring for this event, details on the dataLayer event should be
                                    shared in the table below.
                                    <br></br>
                                    For a quick start you can can copy / paste your JSON object via the button below.
                                </p>
                            </div>

                            {/* Buttons to edit template, add ecommerce event or clear template content. */}
                            <Flex gap="small">
                                <ActionButton
                                    text={jsonBtnText}
                                    type=""
                                    icon={jsonBtnIcon}
                                    onClickFunction={handleEditTemplateEvent}
                                    width={120}
                                />
                                <DropdownMenu
                                    text="Add ecommerce event"
                                    type="primary"
                                    disabled={selectedTemplate?.length > 1}
                                    menuProps={menuProps}
                                    loading={false}
                                    icon={<DownOutlined />}
                                />
                                <ActionButton
                                    text="Clean JSON"
                                    type=""
                                    icon={<RedoOutlined />}
                                    onClickFunction={clearJSON}
                                />
                            </Flex>

                            {/* JSON object structure table */}
                            <Space>
                                <NestedObjectTable
                                    key={selectedStepId}
                                    data={selectedTemplateStr}
                                    updateTemplateFunction={setSelectedTemplate}
                                    setEditedEventTemplateNewVersion={setSelectedTemplate}
                                />
                            </Space>
                        </div>

                        {/* Save button for dataLayer object template */}
                        <div className="drawer-buttons-area">
                            <div className="drawer-buttons-area-shad"></div>
                            <div className="drawer-buttons-area-button">
                                <ActionButton
                                    size="large"
                                    type="primary"
                                    width={140}
                                    onClickFunction={() => handleSaveAction("templates")}
                                >
                                    Save
                                </ActionButton>
                            </div>
                        </div>

                    </Drawer>


                    {/* Cookies acceptance scenario steps modal. */}
                    <Drawer
                        title={cookiesDrawerTitle}
                        placement={defaultPlacement}
                        onClose={closeCookiesDrawer}
                        open={cookiesDrawerVisible}
                        closable={true}
                        width={thirdDrawerWidth}
                    >
                        <ConfirmationModalWindow
                            open={cookiesStepsListChangeModalState}
                            onOk={stepsListChangeModalOkFunction}
                            onCancel={handleCookiesStepsListModalCancel}
                            text={"Are you sure you want to update the steps list?"}
                        />

                        <div key={isConfigParamsUpdatedBeforeSave} className={`modal-elems-rows scenarios-modal-padding`}>
                            <div>
                                <h5 className='small-heading'>CONFIGURATION</h5>
                                <h2 className='header-letter-spacing'>Cookies Acceptance Scenario Steps</h2>
                            </div>

                            {/* Scenario title and starting point. */}
                            <Space size={25}>
                                {/* Switch for scenario status. */}
                                <Space>
                                    <Switch
                                        value={scenarioCookiesStatus}
                                        onChange={(value) => setScenarioCookiesStatus(value)}
                                    />
                                    <label>{scenarioCookiesStatus ? "Required" : "Not required"}</label>
                                </Space>
                                <Space>
                                    {/* Copy steps from another scenario */}
                                    <DropdownMenu
                                        text="Import steps"
                                        menuProps={cookiesStepsScenarioOptions}
                                        type="primary"
                                        loading={false}
                                        icon={<CopyOutlined />}
                                        iconPosition="start"
                                    />
                                    {/* Clear steps list */}
                                    <ActionButton
                                        text={<Space><ClearOutlined />Clear steps</Space>}
                                        onClickFunction={() =>
                                            handleStepsListModalChangeState({
                                                newState: true,
                                                okFunction: () => clearStepsList({
                                                    listUpdateFunction: setScenarioCookiesSteps,
                                                    modalCancelFunction: handleCookiesStepsListModalCancel,
                                                }),
                                                modalStateChangeFunction: setCookiesStepsListChangeModalState,
                                            })
                                        }
                                    />
                                </Space>
                            </Space>

                            {/* Dynamically sortable steps list. */}
                            <div style={{ paddingBottom: "5rem" }}>
                                <h3 className="modal-category">Steps</h3>
                                <div style={{ margin: "auto" }}>
                                    <SortableList
                                        items={scenarioCookiesSteps}
                                        handleDataLayerObjEdit={() => { }}
                                        handleStepsUpdate={setScenarioCookiesSteps}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Save button for scenario steps list */}
                        <div className="drawer-buttons-area">
                            <div className="drawer-buttons-area-shad"></div>
                            <div className="drawer-buttons-area-button">
                                <ActionButton
                                    size="large"
                                    type="primary"
                                    width={140}
                                    onClickFunction={() => handleSaveAction("cookies")}
                                >
                                    Save
                                </ActionButton>
                            </div>
                        </div>

                    </Drawer>


                    {/* Login scenario steps modal. */}
                    <Drawer
                        title={loginDrawerTitle}
                        placement={defaultPlacement}
                        onClose={closeLoginDrawer}
                        open={loginDrawerVisible}
                        closable={true}
                        width={thirdDrawerWidth}
                    >
                        <ConfirmationModalWindow
                            open={loginStepsListChangeModalState}
                            onOk={stepsListChangeModalOkFunction}
                            onCancel={handleLoginStepsListModalCancel}
                            text={"Are you sure you want to update the steps list?"}
                        />

                        <div key={isConfigParamsUpdatedBeforeSave} className={`modal-elems-rows scenarios-modal-padding`}>
                            <div>
                                <h5 className='small-heading'>CONFIGURATION</h5>
                                <h2 className='header-letter-spacing'>Login Scenario Steps</h2>
                            </div>

                            {/* Scenario title and starting point. */}
                            <Space size={25}>
                                {/* Switch for scenario status. */}
                                <Space>
                                    <Switch
                                        value={scenarioLoginStatus}
                                        onChange={(value) => setScenarioLoginStatus(value)}
                                    />
                                    <label>{scenarioLoginStatus ? "Required" : "Not required"}</label>
                                </Space>
                                <Space>
                                    {/* Copy steps from another scenario */}
                                    <DropdownMenu
                                        text="Import steps"
                                        menuProps={loginStepsScenarioOptions}
                                        type="primary"
                                        loading={false}
                                        icon={<CopyOutlined />}
                                        iconPosition="start"
                                    />
                                    {/* Clear steps list */}
                                    <ActionButton
                                        text={<Space><ClearOutlined />Clear steps</Space>}
                                        onClickFunction={() =>
                                            handleStepsListModalChangeState({
                                                newState: true,
                                                okFunction: () => clearStepsList({
                                                    listUpdateFunction: setScenarioLoginSteps,
                                                    modalCancelFunction: handleLoginStepsListModalCancel,
                                                }),
                                                modalStateChangeFunction: setLoginStepsListChangeModalState,
                                            })
                                        }
                                    />
                                </Space>
                            </Space>

                            {/* Dynamically sortable steps list. */}
                            <div style={{ paddingBottom: "5rem" }}>
                                <h3 className="modal-category">Steps</h3>
                                <div style={{ margin: "auto" }}>
                                    <SortableList
                                        items={scenarioLoginSteps}
                                        handleDataLayerObjEdit={() => { }}
                                        handleStepsUpdate={setScenarioLoginSteps}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Save button for scenario steps list */}
                        <div className="drawer-buttons-area">
                            <div className="drawer-buttons-area-shad"></div>
                            <div className="drawer-buttons-area-button">
                                <ActionButton
                                    size="large"
                                    type="primary"
                                    width={140}
                                    onClickFunction={() => handleSaveAction("login")}
                                >
                                    Save
                                </ActionButton>
                            </div>
                        </div>

                    </Drawer>


                    {/* Save button for scenario steps list */}
                    <div className="drawer-buttons-area">
                        <div className="drawer-buttons-area-shad"></div>
                        <div className="drawer-buttons-area-button">
                            <ActionButton
                                size="large"
                                type="primary"
                                width={140}
                                onClickFunction={() => handleSaveAction("steps")}
                            >
                                Save
                            </ActionButton>
                        </div>
                    </div>

                </Drawer>

                {/* Save button for scenarios list */}
                <div className="drawer-buttons-area">
                    <div className="drawer-buttons-area-shad"></div>
                    <div className="drawer-buttons-area-button">
                        <ActionButton
                            size="large"
                            type="primary"
                            width={140}
                            onClickFunction={() => handleSaveAction("scenarios")}
                        >
                            Save
                        </ActionButton>
                    </div>
                </div>

            </Drawer>
        </div>
    );
};

export default ScenariosMenu;
